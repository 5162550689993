// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Archivo-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Archivo-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Archivo-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Archivo-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Archivo-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Archivo-Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Archivo-Semibold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Archivo-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/Archivo/stylesheet.css"],"names":[],"mappings":"AACE;EACE,8BAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,6BAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,+BAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,2BAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ","sourcesContent":["\n  @font-face {\n    font-family: 'Archivo-Regular';\n    src: url('Archivo-Regular.ttf') format('truetype');\n    font-weight: 400;\n    font-display: swap;\n    font-style: normal;\n  }\n  @font-face {\n    font-family: 'Archivo-Medium';\n    src: url('Archivo-Medium.ttf') format('truetype');\n    font-weight: 500;\n    font-display: swap;\n    font-style: normal;\n  }\n  @font-face {\n    font-family: 'Archivo-Semibold';\n    src: url('Archivo-SemiBold.ttf') format('truetype');\n    font-weight: 600;\n    font-display: swap;\n    font-style: normal;\n  }\n  @font-face {\n    font-family: 'Archivo-Bold';\n    src: url('Archivo-Bold.ttf') format('truetype');\n    font-weight: 700;\n    font-display: swap;\n    font-style: normal;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
