import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import GA4React from 'ga-4-react';

import runtimeEnv from '@mars/heroku-js-runtime-env';
import store from './redux';
import PrivateRoute from './auth/PrivateLayout';
import StartPage from './page/StartPage';
import PressKitPage from './page/PressKitPage';
import PrivacyPage from './page/PrivacyPage';
import TermsPage from './page/TermsPage';
import Blog from './page/Blog';
import RampComponent from './page/RampComponent';
import AuthLayout from './auth/AuthLayout';
import * as serviceWorker from './serviceWorker';
import Home from './page/Home';
import SignUp from './page/SignUp';
import SelectTeam from './page/SelectTeam';
import Team from './page/Team';
import Manager from './page/Manager';
import Transfers from './page/Transfers';
import Finance from './page/Finance';
import League from './page/League';
import LoginCallback from './page/LoginCallback';
import PreviewCallback from './page/PreviewCallback';
import HiddenPage from './page/Hidden';
import YouthCamp from './page/YouthCamp';
import YouthCampSignUp from './page/YouthCampSignUp';
import DomesticCup from './page/DomesticCup';
import Rewards from './page/Rewards';
import UserGuide from './page/UserGuide';
import Stuff from './page/Stuff';

import './index.css';
import './assets/style/index.scss';

const { REACT_APP_MIXPANEL_KEY, REACT_APP_SENTRY_DSN } = runtimeEnv();

mixpanel.init(REACT_APP_MIXPANEL_KEY, { ignore_dnt: true });
// Sentry Initialization
Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const ga4react = new GA4React('G-FFPJXZZSGC', 5000);
ga4react.initialize().then(
  (ga4) => {
    ga4.pageview('path');
    ga4.gtag('event', 'pageview', 'path'); // or your custom gtag event
  },
  (err) => {
    console.error(err);
  }
);

const Routes = () => (
  <Switch>
    <Route exact path="/signup" component={SignUp} />
    <Route exact path="/preview" component={PreviewCallback} />
    <Route path="/login/discord_callback" component={LoginCallback} />
    <Route exact path="/press-kit" component={PressKitPage} />
    <Route exact path="/privacy" component={PrivacyPage} />
    <Route exact path="/terms" component={TermsPage} />
    <Route exact path="/blog" component={Blog} />
    <Route exact path="/test-ramp" component={RampComponent} />

    <Route exact path="/select-team/:leagueCodeParam?" component={SelectTeam} />
    <PrivateRoute exact path="/home" component={Home} />
    <PrivateRoute path="/home/player/:playerId" component={Home} />
    <PrivateRoute path="/league" component={League} />
    <PrivateRoute path="/finance" component={Finance} />
    <PrivateRoute path="/team" component={Team} />
    <PrivateRoute path="/manager" component={Manager} />
    <PrivateRoute path="/transfers" component={Transfers} />
    <PrivateRoute path="/domestic-cup" component={DomesticCup} />
    <PrivateRoute path="/user-guide" component={UserGuide} />
    <PrivateRoute path="/stuff" component={Stuff} />
    <PrivateRoute path="/rewards" emptyLayout component={Rewards} />
    <PrivateRoute exact path="/youth-camp" emptyLayout component={YouthCamp} />
    <PrivateRoute
      exact
      path="/youth-camp-signup"
      emptyLayout
      component={YouthCampSignUp}
    />
    <PrivateRoute
      exact
      path="/_page_that_consist_discord"
      component={HiddenPage}
    />
    <Route
      exact
      path="/ads.txt"
      component={() => {
        window.location.href = '//config.playwire.com/dyn_ads/1025083/74579/ads.txt';
        return null;
    }}/>
    <Route exact path="/" component={StartPage} />
  </Switch>
);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <AuthLayout>
          <Routes />
        </AuthLayout>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
