import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as OkIcon } from '../../../assets/img/ok-tick.svg';
import FormInput from '../../FormInput';
import { sendRequestToWaitList } from '../../../redux/actions/SignUp';
import { waitlistData } from '../../../redux/selector/SignUp';
import { StyledPopUp } from '../styled';
import {
  StyledWaitlistWrapper,
  StyledLeftSection,
  StyledRightSection,
  StyledHeader,
  StyledSubheader,
  StyledInputContainer,
  StyledDiscordButton,
  StyledIconContainer,
} from './styled';

const WaitlistPopup = () => {
  const dispatch = useDispatch();
  const { waitlistRequestLoading, waitlistRequestStatus } =
    useSelector(waitlistData);
  const [input, setInput] = React.useState('');
  const [isEmailValid, setEmailValidity] = React.useState(false);

  const basicEmailValidation = (email) => /^\S+@\S+\.\S+$/.test(email);

  const onInputChange = (e) => {
    const val = e.target.value;
    if (basicEmailValidation(val)) {
      setEmailValidity(true);
    }
    setInput(val);
  };

  const sendEmailToWaitlist = () => {
    dispatch(sendRequestToWaitList(input));
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && input) {
      e.preventDefault();
      e.stopPropagation();
      sendEmailToWaitlist();
    }
  };

  const joinDiscord = () => {
    window.open('https://discord.gg/jAjgAURECT', '_blank');
  };
  const isEmailSent = waitlistRequestStatus === 200;
  return (
    <StyledPopUp>
      <StyledWaitlistWrapper>
        <StyledLeftSection isEmailSent={isEmailSent}>
          {!isEmailSent ? (
            <>
              <StyledHeader>We’re full</StyledHeader>
              <StyledSubheader>
                Drop us your email and we'll let you know when more servers
                open.
              </StyledSubheader>
              <StyledInputContainer>
                <FormInput
                  inputVal={input}
                  onChange={onInputChange}
                  onKeyPress={(e) => onKeyDown(e)}
                  placeholder="Enter email address"
                  disabledButton={
                    !input || waitlistRequestLoading || !isEmailValid
                  }
                  onButtonClick={sendEmailToWaitlist}
                />
              </StyledInputContainer>
            </>
          ) : (
            <>
              <StyledIconContainer>
                <OkIcon />
              </StyledIconContainer>
              <StyledHeader>Got it!</StyledHeader>
              <StyledSubheader>
                We’ll drop you a note as soon as we have more teams.
              </StyledSubheader>
            </>
          )}
        </StyledLeftSection>
        <StyledRightSection>
          <StyledHeader>Join our Discord</StyledHeader>
          <StyledSubheader>
            Be the first to know when a team opens up and learn about our league
            while you wait.
          </StyledSubheader>
          <StyledDiscordButton onClick={joinDiscord}>
            Join the server now
          </StyledDiscordButton>
        </StyledRightSection>
      </StyledWaitlistWrapper>
    </StyledPopUp>
  );
};
export default WaitlistPopup;
